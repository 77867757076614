import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby'; // Ensure this import is present
import Title from '../../Title/Title';
import Text from '../../Text/Text';
import {
  HeadingContainerStyled,
  HeadingContent,
  HeadingDownloadIconContainer,
} from './HeadingContainer.css';
import Fade from 'react-reveal/Fade';
import { useTextAnimationSecondary } from '../../../hooks/useTextAnimation';
import IntersectionVisible from 'react-intersection-visible';
import downloadIcon from '../../../../static/images/download-icon.png';

const HeadingContainer = ({
  hideBorder,
  className,
  isHTML = false,
  withRedPoint,
  noAnimation,
  image,
  image_link,
  title,
  title_link,
  body,
  link_to_pdf,
  block_link,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const titleRef = useRef(null);
  const titleProps = {
    type: 'h3',
    as: 'h3',
    customClassName: 'HeadingContainer__Title',
    withRedPoint: withRedPoint === false ? false : true,
  };
  useTextAnimationSecondary({ element: titleRef }, [isVisible]);

  // Prevent event propagation for specific links
  const handleLinkClick = (e) => {
    e.stopPropagation();
  };

  const content = (
    <Fade bottom distance='30px'>
      <HeadingContainerStyled
        className={`${className} ${body ? '' : 'NoMarginBottom'}`}
      >
        {hideBorder ? (
          ''
        ) : (
          <hr className='HeadingContainer__Section-BreakLine' />
        )}
        <div className={`HeadingContainer__TitleSection`}>
          {title_link ? (
            <a href={title_link} target='_blank' onClick={handleLinkClick}>
              <Title ref={noAnimation ? null : titleRef} {...titleProps}>
                {title}
              </Title>
            </a>
          ) : (
            <Title ref={noAnimation ? null : titleRef} {...titleProps}>
              {title}
            </Title>
          )}
          <HeadingContent className='HeadingContainer__TitleBody'>
            {link_to_pdf && (
              <HeadingDownloadIconContainer
                target='_blank'
                download
                href={link_to_pdf}
                rel='noreferrer noopener'
                onClick={handleLinkClick} // Stop propagation here
              >
                <img
                  className={'download-icon'}
                  src={downloadIcon}
                  alt='Download Icon'
                />
              </HeadingDownloadIconContainer>
            )}

            {image && (
              <a
                href={image_link || '#'}
                target='_blank'
                onClick={image_link ? handleLinkClick : undefined}
                style={{ marginBottom: 20, display: 'block' }}
              >
                <img src={image} alt='' />
              </a>
            )}
            {isHTML ? (
              <Text
                as='div'
                type='secondary'
                customClassName='HeadingContainer__TitleBody'
                dangerouslySetInnerHTML={{ __html: body }}
              />
            ) : (
              body && (
                <Text
                  as='div'
                  type='secondary'
                  customClassName='HeadingContainer__TitleBody'
                >
                  {body}
                </Text>
              )
            )}
          </HeadingContent>
        </div>
      </HeadingContainerStyled>
    </Fade>
  );

  return (
    <IntersectionVisible onShow={() => setIsVisible(true)}>
      {block_link ? (
        <Link
          to={block_link}
          style={{ textDecoration: 'none', color: 'inherit' }}
        >
          {content}
        </Link>
      ) : (
        content
      )}
    </IntersectionVisible>
  );
};

HeadingContainer.propTypes = {
  hideBorder: PropTypes.bool,
  className: PropTypes.string,
  isHTML: PropTypes.bool,
  withRedPoint: PropTypes.bool,
  noAnimation: PropTypes.bool,
  image: PropTypes.string,
  image_link: PropTypes.string,
  title: PropTypes.string,
  title_link: PropTypes.string,
  body: PropTypes.string,
  link_to_pdf: PropTypes.string,
  block_link: PropTypes.string,
};

export default HeadingContainer;
