import styled from '@emotion/styled';
import { theme } from '../../styles';

export const ProjectTemplateHeader = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  border-bottom: 3px solid black;

  @media (min-width: ${theme.breakpoints.md}) {
    flex-direction: row;
  }

  .ProjectTemplateHeader__Img {
    width: 50% !important;
    max-width: 580px;

    @media (max-width: ${theme.breakpoints.md}) {
      width: 100% !important;
      height: calc(100vw - 20px) !important;
      max-height: 580px;
    }
  }
`;

export const ProjectTemplateHeaderContent = styled.div`
  width: 100%;
  position: relative;

  @media (min-width: ${theme.breakpoints.md}) {
    width: calc(100% - 350px);
  }

  .HeaderContent__Title {
    padding: 30px 60px 30px 0;
    margin: 0;

    @media (min-width: ${theme.breakpoints.md}) {
      line-height: 1.2;
      padding: 30px 70px 30px 45px;
      font-size: 45px;
    }
  }

  .HeaderContent__Tagline {
    margin: 0;
    padding: 10px 0;
    border-bottom: 3px solid black;

    @media (min-width: ${theme.breakpoints.md}) {
      padding: 10px 45px;
    }
  }

  .download-icon {
    position: absolute;
    right: 0px;
    top: 70px;
    cursor: pointer;
    transition: transform 300ms;
    width: 50px;

    @media (min-width: ${theme.breakpoints.md}) {
      top: 120px;
      width: 68px;
    }

    &:hover {
      transform: scale(0.95);
    }
  }
`;

export const ProjectTemplateContainer = styled.div`
  margin-top: 71px;

  .HeadingContainerStyled {
    margin-top: 60px;

    .HeadingContainer__TitleBody {
      margin-left: auto;
      width: 100%;

      @media (min-width: ${theme.breakpoints.md}) {
        width: calc(100% - 390px);
      }

      h1 {
        font-family: ${theme.fontFamily.primary};
        font-size: ${theme.scale(theme.fonts.xxxl_small)};
        margin-bottom: 10px;
      }

      h2 {
        font-family: ${theme.fontFamily.primary};
        font-size: ${theme.scale(theme.fonts.xxl_large)};
        margin-bottom: 10px;
      }

      h3 {
        font-family: ${theme.fontFamily.primary};
        font-size: ${theme.scale(theme.fonts.xxl_medium)};
        margin-bottom: 10px;
        line-height: 1.3;
      }

      h4 {
        font-family: ${theme.fontFamily.primary};
        font-size: ${theme.scale(theme.fonts.xxl_small)};
        margin-bottom: 10px;
      }

      h5 {
        font-family: ${theme.fontFamily.primary};
        font-size: ${theme.scale(theme.fonts.xl_medium)};
        margin-bottom: 10px;
      }

      h6 {
        font-family: ${theme.fontFamily.primary};
        font-size: ${theme.scale(theme.fonts.l)};
        margin-bottom: 10px;
      }
    }
  }

  .ProjecRelated__Title {
    margin-bottom: 70px;
    border-bottom: 3px solid black;
    padding-bottom: 15px;
  }
`;
