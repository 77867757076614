import styled from '@emotion/styled';
import { theme } from '../../../styles';

export const HeadingContainerStyled = styled.div`
  margin-top: 40px;

  &.NoMarginBottom {
    .HeadingContainer__TitleSection {
      margin-bottom: 0;

      @media (min-width: ${theme.breakpoints.md}) {
        margin-bottom: 65px;
      }
    }

    .HeadingContainer__Title {
      @media (min-width: ${theme.breakpoints.md}) {
        margin-bottom: 30px;
      }
    }
  }

  .HeadingContainer__Section-BreakLine {
    border-top: 3px solid black;
    margin-bottom: 50px;

    @media (min-width: ${theme.breakpoints.l}) {
      margin-bottom: ${theme.scale('70px')};
    }
  }

  .HeadingContainer__Title {
    text-transform: uppercase;
    color: black;
    margin-bottom: 80px;

    @media (min-width: ${theme.breakpoints.md}) {
      margin-bottom: ${theme.scale('80px')};
      width: 30%;
      min-width: 300px;
    }

    @media (min-width: ${theme.breakpoints.l}) {
      min-width: 400px;
    }
  }

  .HeadingContainer__TitleSection {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    margin-bottom: 65px;
    align-items: baseline;

    @media (min-width: ${theme.breakpoints.md}) {
      flex-direction: row;
      align-items: flex-start;
      margin-bottom: ${theme.scale('90px')};
    }
  }

  .HeadingContainer__TitleBody {
    color: black;
    text-align: left;
    margin-bottom: 0;

    h3 {
      line-height: 1.4;
    }
  }
`;

export const HeadingContent = styled.div`
  position: relative;

  img {
    max-width: 150px;
    min-height: 68px;
  }
`;

export const HeadingDownloadIconContainer = styled.a`
  position: absolute;
  top: 0;
  right: 0;
`;
