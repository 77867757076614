import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { getQuery } from '../functions/getQuery';
import ProjectTiles from '../components/ProjectTiles/ProjectTiles';
import SEO from '../components/SEO';
import Img from 'gatsby-image';
import {
  ProjectTemplateHeader,
  ProjectTemplateHeaderContent,
  ProjectTemplateContainer,
} from '../components/ProjectTemplate/ProjectTemplate.css';
import Title from '../components/Title/Title';
import HeadingContainer from '../components/PageSections/HeadingContainer/HeadingContainer';
import downloadIcon from '../../static/images/download-icon.png';
import Fade from 'react-reveal/Fade';
import Footer from '../components/Footer/Footer';

const ProjectTemplate = ({ data, pageContext: { locale, uid }, location }) => {
  const query = data.allPrismicProjectSection.edges.find(
    ({ node }) => node.uid === uid
  ).node.data;

  const relatedProjectsQuery = getQuery(['projectsContent', 'data'], data);

  const relatedProjects = data.allPrismicProjectSection.edges.filter(
    ({ node }) => {
      const matchingTags = [];

      if (node?.data?.tags?.text && node.data.tags.text.includes(',')) {
        node.data.tags.text.split(',').forEach((tag) => {
          if (query?.tags?.text?.includes(tag)) {
            matchingTags.push(tag);
          }
        });
      } else if (
        node?.data?.tags?.text &&
        query?.tags?.text?.includes(node.data.tags.text)
      ) {
        matchingTags.push(node.data.tags.text);
      }

      return node.uid !== uid && matchingTags.length > 0;
    }
  );

  const country = query.countries.map(({ country }) => country).join(',');

  return (
    <>
      <SEO location={location} pathname={location.pathname} locale={locale} />
      <ProjectTemplateContainer>
        <ProjectTemplateHeader>
          {query?.photo_horizontal?.headerImage?.childImageSharp?.fluid && (
            <Img
              fluid={query.photo_horizontal.headerImage.childImageSharp.fluid}
              imgStyle={{ objectFit: 'contain' }}
              className='ProjectTemplateHeader__Img'
            />
          )}
          <ProjectTemplateHeaderContent>
            <Title
              as='h2'
              type='h3_secondary'
              customClassName='HeaderContent__Tagline'
            >
              {country}
              {query.year ? ` - ${query.year}` : ''}
            </Title>
            {query.title ? (
              <Title
                as='h1'
                type='h2_secondary'
                customClassName='HeaderContent__Title'
              >
                {query.title.text}
              </Title>
            ) : (
              ''
            )}
            {query.download_project_pdf?.url ? (
              <a
                href={query.download_project_pdf.url}
                target='_blank'
                download
                rel='noreferrer'
              >
                <img
                  className={'download-icon'}
                  src={downloadIcon}
                  alt='Download Icon'
                />
              </a>
            ) : (
              ''
            )}
          </ProjectTemplateHeaderContent>
        </ProjectTemplateHeader>
        {query.project_sections.length > 0
          ? query.project_sections.map((section, index) => {
              return (
                <HeadingContainer
                  key={`${section.project_section_title.text}--${index}`}
                  title={section.project_section_title.text}
                  body={section.project_section_body.html}
                  isHTML
                  hideBorder={index === 0 ? true : false}
                />
              );
            })
          : ''}
        {relatedProjectsQuery.similar_projects_title &&
        relatedProjects.length > 0 ? (
          <>
            <Fade bottom distance='30px'>
              <Title
                customClassName={'ProjecRelated__Title'}
                as='h2'
                type='h3_secondary'
              >
                {relatedProjectsQuery.similar_projects_title.text}
              </Title>
            </Fade>
            <ProjectTiles
              className='ProjectTiles'
              query={relatedProjects}
              numberOfVisibleArticles={
                data.prismicArticlesSection?.data?.number_of_articles_visible
              }
              numberOfArticlesToLoad={
                data.prismicArticlesSection?.data?.number_of_articles_to_load
              }
              loadMore={
                data.prismicArticlesSection?.data?.load_more_button_label?.text
              }
            />
          </>
        ) : (
          ''
        )}
        <Footer />
      </ProjectTemplateContainer>
    </>
  );
};

ProjectTemplate.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ProjectTemplate;

export const projectTemplateQuery = graphql`
         query projectQuery($locale: String!) {
           prismicArticlesSection(lang: { eq: $locale }) {
             data {
               load_more_button_label {
                 text
               }
               number_of_articles_visible
               number_of_articles_to_load
             }
           }
           allPrismicProjectSection(filter: { lang: { eq: $locale } }) {
             edges {
               node {
                 uid
                 data {
                   countries {
                     country
                   }
                   download_project_pdf {
                     url
                   }
                   highlight_on_homepage
                   industry_selector_eng
                   industry_selector_fr
                   excerpt {
                     text
                   }
                   photo_horizontal {
                     headerImage: localFile {
                       childImageSharp {
                         fluid(toFormat: JPG) {
                           ...GatsbyImageSharpFluid_noBase64
                         }
                       }
                     }
                   }
                   photo_horizontal {
                     desktop: localFile {
                       childImageSharp {
                         fluid(toFormat: JPG) {
                           ...GatsbyImageSharpFluid_noBase64
                         }
                       }
                     }
                   }
                   photo_horizontal {
                     mobile: localFile {
                       childImageSharp {
                         fluid(toFormat: JPG) {
                           ...GatsbyImageSharpFluid_noBase64
                         }
                       }
                     }
                   }
                   project_sections {
                     include_section_on_homepage_project_tile
                     project_section_body {
                       html
                     }
                     project_section_title {
                       text
                     }
                   }
                   tags {
                     text
                   }
                   title {
                     text
                   }
                 }
               }
             }
           }
           projectsContent: prismicProjectTile(lang: { eq: $locale }) {
             data {
               similar_projects_title {
                 text
               }
             }
           }
         }
       `;
